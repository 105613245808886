import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Link, useStaticQuery, graphql } from "gatsby";

const Nav = () => {
  const data = useStaticQuery(query);

  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return (
    <div>
      <nav
        className="uk-navbar-container"
        uk-navbar="mode: click"
        key={isClient ? "client" : "server"}
      >
        <div className="uk-navbar-left">
          <h1 className="uk-margin-remove-bottom uk-text-bold">
            <Link to="/" className="uk-navbar-item uk-logo uk-margin-left">
              <img
                style={{ height: "60px", width: "60px" }}
                src={data.strapiGlobal.favicon.localFile.publicURL}
                alt={data.strapiGlobal.siteName}
              />
              <div className="uk-visible@s">{data.strapiGlobal.siteName}</div>
            </Link>
          </h1>
        </div>
        <div className="uk-navbar-right">
          <div className="uk-visible@s">
            <Link
              className="uk-button uk-button-default uk-margin-right"
              to="/products"
            >
              products
            </Link>

            <button
              className="uk-button uk-button-default uk-margin-right"
              style={{ paddingRight: "6px" }}
              type="button"
            >
              blog
              <FontAwesomeIcon
                icon={faCaretDown}
                style={{ marginLeft: "30px" }}
              />
            </button>
            <div uk-dropdown="mode: click" className="uk-navbar-dropdown">
              <ul className="uk-nav uk-dropdown-nav uk-navbar-dropdown-nav">
                {data.allStrapiCategory.edges.map((category, i) => (
                  <li key={`category__${category.node.slug}`}>
                    <Link to={`/category/${category.node.slug}`}>
                      {category.node.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <button
            className="uk-navbar-toggle uk-hidden@s"
            type="button"
            uk-toggle="target: #offcanvas-nav-primary"
            uk-navbar-toggle-icon="uk-navbar-toggle-icon"
          />

          <div id="offcanvas-nav-primary" uk-offcanvas="overlay: true">
            <div className="uk-offcanvas-bar uk-flex uk-flex-column">
              <ul className="uk-nav uk-nav-primary uk-margin-auto-vertical">
                <li>
                  <Link to="/products">
                    <span className="uk-margin-small-right"></span>products
                  </Link>
                </li>
                <li className="uk-parent">
                  <a href="#">
                    <span className="uk-margin-small-right"></span>
                    blog
                  </a>
                  <ul className="uk-nav-sub">
                    {data.allStrapiCategory.edges.map((category, i) => (
                      <li key={`category__${category.node.slug}`}>
                        <Link to={`/category/${category.node.slug}`}>
                          {category.node.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

const query = graphql`
  query {
    strapiGlobal {
      siteName
      favicon {
        url
        localFile {
          publicURL
        }
      }
    }
    allStrapiCategory {
      edges {
        node {
          slug
          name
        }
      }
    }
  }
`;
export default Nav;
