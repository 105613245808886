import React from "react";
import PropTypes from "prop-types";
import Nav from "./nav";
import Seo from "./seo";
import Footer from "./footer";

const Layout = ({ children, seo }) => {
  return (
    <>
      <Seo seo={seo} />
      <Nav />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
