import React from "react";

const Footer = () => {
  return (
    <footer
      id="page-footer"
      className="uk-margin-large-top"
      style={{ backgroundColor: "rgb(248, 249, 250)" }}
    >
      <div
        className="uk-container uk-text-center uk-padding-small"
        style={{ fontSize: "0.9rem", color: "#888" }}
      >
        <h5 className="uk-text-uppercase">disclosure</h5>
        <p>
          TheBabyWay believes in transparency and we want to disclose that we’ve
          included certain products and links to products on the site that will
          earn an affiliate commission for any purchases you make. Our mission
          is to provide relevant information, however, please keep in mind that
          we are also a for-profit business, and we believe you should be too,
          unless you have some charitable endeavor in mind. TheBabyWay is also a
          participant in the Amazon Services LLC Associates Program, an
          affiliate advertising program designed to provide a means for sites to
          earn advertising fees by advertising and linking to Amazon.com.
        </p>
      </div>
      <div
        className="uk-text-center uk-padding-small"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2021 Copyright:
        <a
          className="uk-link-text uk-margin-small-left"
          href="https://www.thebabyway.com/"
        >
          TheBabyWay.com
        </a>
      </div>
    </footer>
  );
};

export default Footer;
